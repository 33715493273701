import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL, MAX_COLS } from "./../../global";



export const getCustomColumnCount = (
  tableId,
  certificateStaticTables,
  staticTables,
  isStatic = null,
  isCertificate = null,
  tables = null
) => {
  let tbl = tables;
  if (!tbl) {
    tbl =
      isStatic === "static"
        ? isCertificate === "certificate"
          ? certificateStaticTables
          : staticTables
        : null;
  }

  let count = 0;
  if (!tbl) return count;
  for (let i = 0; i < tbl.length; i++) {
    if (tbl[i].id == tableId) {
      for (let j = 0; j < MAX_COLS; j++) {
        if (tbl[i]["c" + (j + 1)] != null) {
          count++;
        } else {
          break;
        }
      }
    }
  }
  return count;
};

export const clearPreviousReadings = async (table, ids = "") => {
  // 1. if no ids to clear, return true
  if (ids === "" || !ids) return true;

  // 2. remove invalid ids from the list
  ids = ids.map((id) => {
    if (id > 0) return id;
    else return 0;
  });

  // 3. delete records with respective ids from given table
  try {
    let res = await axiosWithToken.delete(
      BASE_URL + `${table}/bulk?_ids=${ids.toString()}`
    );
    return true;
  } catch (error) {
    console.error(
      `[ERRRO] Failed while clearing previous readings, error: ${error}`
    );
  }
  return false;
};

// TODO: remove this unused function
// export const fetchSettings = (setShouldShowAmendment) => {
//   return axiosWithToken.get(BASE_URL + `settings`).then((res) => {
//     let remark = null,
//       amd = false,
//       settings = res.data;
//     settings.some((setting) => {
//       if (setting.keyName === "remark") {
//         remark = setting.value;
//       } else if (
//         setting.keyName === "amendment" &&
//         setting.value.toLowerCase() === "true"
//       ) {
//         amd = true;
//       }
//     });
//     setShouldShowAmendment(amd);
//     return res;
//   });
// };
